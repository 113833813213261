import {
  ContactEmails,
  ContactMedia,
  SectionHeader,
  TextContent,
} from './components';

export const Contact = () => {
  return (
    <div>
      <SectionHeader>CONTACT</SectionHeader>
      <ContactMedia />
      <TextContent style={{ marginTop: '2rem', textAlign: 'center' }}>
        <ContactEmails />
      </TextContent>
    </div>
  );
};
