import styled from 'styled-components';
import { Section, SectionHeader, Content } from './components';
import { videos } from './data/videos';
import { photos } from './data/photos';

export const Media = () => {
  return (
    <>
      <SectionHeader>MEDIA</SectionHeader>
      <Section>
        <Content>
          {videos.map((video) => (
            <Video
              key={video.title}
              height="415"
              src={video.link}
              title={video.title}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          ))}
          {photos.map((image) => (
            <Image key={image.src} src={image.src} alt={image.alt} />
          ))}
        </Content>
      </Section>
    </>
  );
};

const Video = styled.iframe`
  margin-bottom: 2rem;
  width: 100%;
  max-width: 800px;
`;

const Image = styled.img`
  margin-bottom: 2rem;
  width: 100%;
  max-width: 800px;
`;
