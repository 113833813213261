import styled from 'styled-components';

export const Section = styled.section`
  padding: 0 2rem;
  margin-bottom: 2rem;

  @media only screen and (max-width: 768px) {
    padding: 0 1rem;
  }
`;
