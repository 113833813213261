import styled from 'styled-components';
import { AnglesDown } from './icons';

export const SplashScreen = () => {
  const handleClick = () => {
    const element = document.getElementById('band');

    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest',
    });
  };

  return (
    <Wrapper data-testid="SplashScreen">
      <Background onClick={handleClick} />
      {/* <Logo src="allochiria_logo.jpg" alt="Allochiria logo" /> */}
      <IconAnimation onClick={handleClick} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  overflow: hidden;
`;

const Background = styled.div`
  background-image: url('commotion_cover_art_no_creds.jpg');
  background-color: #000717;
  background-size: auto 100%;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-position: center center;
  height: 100vh;

  @media only screen and (min-width: 924px) {
    background-image: url('commotion_cover_art.jpg');
  }

  @supports (-webkit-touch-callout: inherit) {
    background-attachment: scroll;
  }
`;

const IconAnimation = styled(AnglesDown)`
  &:hover {
    cursor: pointer;
  }
  width: 2rem;
  height: 2rem;
  position: absolute;
  fill: #000717;
  left: 50%;
  bottom: 4px;
  background: #fed267;

  @media only screen and (min-width: 924px) {
    background: transparent;
    fill: #caa040;
    bottom: 1rem;
    left: 1rem;
  }

  opacity: 0;
  animation: flickerAnimation 6s ease-in-out infinite;

  @keyframes flickerAnimation {
    0% {
      opacity: 0;
    }
    30% {
      opacity: 0;
    }
    70% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
`;

/*
const Logo = styled.img`
  width: 700px;
  display: block;
  position: absolute;
  top: calc(50% - 90px);
  left: calc(50% - 350px);
  box-shadow: -2px 3px 10px -1px black;

  @media only screen and (max-width: 1280px) {
    width: 500px;
    top: calc(50% - 62px);
    left: calc(50% - 250px);
  }

  @media only screen and (max-width: 768px) {
    width: 300px;
    top: calc(50% - 37px);
    left: calc(50% - 150px);
  }
`;
*/
