export const photos = [
  {
    src: '/photos/cristina alossi 9 2023.jpg',
    alt: 'cristina alossi 9 2023',
  },
  {
    src: '/photos/cristina alossi 11 2023.jpg',
    alt: 'cristina alossi 11 2023',
  },
  {
    src: '/photos/cristina alossi 19 2023.jpg',
    alt: 'cristina alossi 19 2023',
  },
  {
    src: '/photos/cristina alossi 31-bw 2023.jpg',
    alt: 'cristina alossi 31-bw 2023',
  },
  {
    src: '/photos/cristina alossi 37 2023.jpg',
    alt: 'cristina alossi 37 2023',
  },
  {
    src: '/photos/dearohwell 1, gagarin release 2023.jpg',
    alt: 'dearohwell 1, gagarin release 2023.jpg',
  },
  {
    src: '/photos/dearohwell 2, gagarin release 2023.jpg',
    alt: 'dearohwell 2, gagarin release 2023.jpg',
  },
  {
    src: '/photos/dearohwell 3, gagarin release 2023.jpg',
    alt: 'dearohwell 3, gagarin release 2023.jpg',
  },
  {
    src: '/photos/dearohwell 4, gagarin release 2023.jpg',
    alt: 'dearohwell 4, gagarin release 2023.jpg',
  },
  {
    src: '/photos/dearohwell 5, gagarin release 2023.jpg',
    alt: 'dearohwell 5, gagarin release 2023.jpg',
  },
  {
    src: '/photos/dearohwell 6, gagarin release 2023.jpg',
    alt: 'dearohwell 6, gagarin release 2023.jpg',
  },
  {
    src: '/photos/dearohwell 7, gagarin release 2023.jpg',
    alt: 'dearohwell 7, gagarin release 2023.jpg',
  },
  {
    src: '/photos/giannis negris, gagarin release 2023.jpg',
    alt: 'giannis negris, gagarin release 2023.jpg',
  },
];
