export const epkLinks = [
  {
    link: 'https://www.dropbox.com/scl/fi/748bpvdyaucmufq0hoq70/Allochiria-bio-EN-2024.pdf?rlkey=q0w9b22nak2uxgfjgv7boaa2x&dl=0',
    label: 'Biography (pdf)',
  },
  {
    link: 'https://www.dropbox.com/scl/fi/skhxp00qwbfhq5hyjtjrm/ALLOCHIRIA-2024-TOUR-RIDER-full.pdf?rlkey=nkjm9yyngxnup5asw1dkkhr7y&dl=0',
    label: 'Tour rider 2024 (pdf)',
  },
  {
    link: 'https://www.dropbox.com/scl/fi/kih3d9g9kwtinepcsbwxs/ALLOCHIRIA-2024-summer-tour-TECH-RIDER.pdf?rlkey=66bqgdq4ug4blwhur9b6zd32f&dl=0',
    label: 'Technical rider (pdf)',
  },
  // {
  //   link: 'https://www.dropbox.com/scl/fi/eyebqwny4nbtj2c5uzwgm/ALLOCHIRIA-2024-TECH-RIDER.pdf?rlkey=it7k5jz7oba9yt13m5akajwq7&dl=0',
  //   label: 'Technical rider (pdf)',
  // },
  {
    link: 'https://www.dropbox.com/scl/fo/gm5arit48dwhhafq2iygi/ALr4pw8tiTy5UycKVq9UKRo?rlkey=78qdhr67ape9wtywr1c4f62v4&dl=0',
    label: 'Summer tour 2024 posters & templates (Dropbox)',
  },
  {
    link: 'https://www.dropbox.com/scl/fi/wruyrtp8rs0g6ahgng2w3/allochiria-photos-2023.zip?rlkey=fkhr5cytmnyxqbbm3fktf3ov8&dl=0',
    label: 'Band photos (Dropbox)',
  },
  {
    link: 'https://www.dropbox.com/sh/uv4ult9x0hstoyv/AADzHBc__kobC8e7WG2nZqrva?dl=0',
    label: 'Logo pack (Dropbox)',
  },
];
