export const menuItems = [
  {
    id: '/#band',
    label: 'BAND',
  },
  {
    id: '/#shows',
    label: 'SHOWS',
  },
  {
    id: '/shows-archive',
    label: 'SHOWS ARCHIVE',
  },
  {
    id: '/#discography',
    label: 'DISCOGRAPHY',
  },
  {
    id: '/#media',
    label: 'MEDIA',
  },
  {
    id: 'https://music.allochiria.com/merch',
    label: 'MERCH',
  },
  {
    id: '/#contact',
    label: 'CONTACT',
  },
];
