import { FlexWrapper } from './FlexWrapper';
import { contactMedia } from '../data/contactMedia';

export const ContactMedia = () => {
  return (
    <FlexWrapper
      style={{ flexDirection: 'row', justifyContent: 'space-between' }}
    >
      {contactMedia.map((med) => (
        <div key={med.name}>
          <a href={med.link} target="_blank" rel="noopener noreferrer">
            {med.icon}
          </a>
        </div>
      ))}
    </FlexWrapper>
  );
};
