import styled from 'styled-components';
import { Section, SectionHeader, TextContent, SingleShow } from './components';
import { allShows } from './data/allShows';
import { Masthead } from './Masthead';

export const ShowsArchive = () => {
  const shows2024 = allShows.filter((sh) => sh.year === 2024);
  const shows2023 = allShows.filter((sh) => sh.year === 2023);
  const shows2022 = allShows.filter((sh) => sh.year === 2022);
  const shows2021 = allShows.filter((sh) => sh.year === 2021);
  const shows2020 = allShows.filter((sh) => sh.year === 2020);
  const shows2019 = allShows.filter((sh) => sh.year === 2019);
  const shows2018 = allShows.filter((sh) => sh.year === 2018);
  const shows2017 = allShows.filter((sh) => sh.year === 2017);
  const shows2016 = allShows.filter((sh) => sh.year === 2016);
  const shows2015 = allShows.filter((sh) => sh.year === 2015);
  const shows2014 = allShows.filter((sh) => sh.year === 2014);
  const shows2013 = allShows.filter((sh) => sh.year === 2013);
  const shows2012 = allShows.filter((sh) => sh.year === 2012);
  const shows2011 = allShows.filter((sh) => sh.year === 2011);
  const shows2010 = allShows.filter((sh) => sh.year === 2010);
  const shows2009 = allShows.filter((sh) => sh.year === 2009);

  const all = [
    shows2024,
    shows2023,
    shows2022,
    shows2021,
    shows2020,
    shows2019,
    shows2018,
    shows2017,
    shows2016,
    shows2015,
    shows2014,
    shows2013,
    shows2012,
    shows2011,
    shows2010,
    shows2009,
  ];

  return (
    <>
      <Masthead />
      <Section>
        <SectionHeader>ALL ALLOCHIRIA SHOWS</SectionHeader>
      </Section>
      {all
        .filter((v) => v.length > 0)
        .map((list) => (
          <Section key={list[0].year}>
            <YearHeader>{list[0].year}</YearHeader>
            <TextContent style={{ fontSize: 14 }}>
              {list.map((show) => (
                <SingleShow key={show.date} show={show} />
              ))}
            </TextContent>
          </Section>
        ))}
    </>
  );
};

const YearHeader = styled(SectionHeader)`
  font-size: 1.25rem;
  margin-top: 1rem;
  padding-top: 0;
`;
