import { TextContent, SectionHeader, SingleShow } from './components';
import { shows, futureShows } from './data/shows';

export const Shows = () => {
  return (
    <>
      <SectionHeader>SHOWS</SectionHeader>
      <TextContent>
        <h4>Upcoming</h4>
        {futureShows.length > 0 ? (
          futureShows.map((show) => <SingleShow key={show.date} show={show} />)
        ) : (
          <em>Stay tuned for upcoming shows</em>
        )}
        <div style={{ height: 32 }} />
        <h4>Past</h4>
        {shows.map((show) => (
          <SingleShow key={show.date} show={show} />
        ))}
        <em style={{ fontSize: 14 }}>
          Note: This is not the full list of our past shows. To see the
          extensive list{' '}
          <a href="/shows-archive" style={{ color: 'black' }}>
            head to our shows archive
          </a>
          .
        </em>
      </TextContent>
    </>
  );
};
