export const BandcampIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 18 8"
      width={22}
      height={22}
      {...props}
    >
      <path d="M16.108 1.726c1.322 0 1.824 1.014 1.892 1.938h-.82c-.107-.71-.484-1.136-1.198-1.136-1.052 0-1.39 1.055-1.39 1.998 0 .913.212 2.04 1.38 2.04.57 0 1.063-.447 1.207-1.259H18c-.087.842-.58 2.03-2.057 2.03-1.419 0-2.258-1.127-2.258-2.659 0-1.643.752-2.952 2.423-2.952zM8.938 0L6.435 7.336H0L2.503 0h6.435zm.827 0v2.612h.02c.356-.55.845-.8 1.419-.81 1.306 0 2.049 1.14 2.049 2.612 0 1.25-.49 2.922-2.162 2.922-.837 0-1.185-.51-1.354-.8h-.019v.65h-.78V0h.827zm1.288 2.592c-1.194 0-1.325 1.371-1.325 2.222 0 1.391.818 1.762 1.344 1.762.855 0 1.28-.782 1.322-1.843l.004-.18c0-.67-.066-1.96-1.345-1.96z" />
    </svg>
  );
};
