import { SectionHeader, FlexWrapper } from './components';
import { AlbumDetails } from './components/AlbumDetails';
import { albums } from './data/albums';

export const Discography = () => {
  return (
    <>
      <SectionHeader>DISCOGRAPHY</SectionHeader>
      <FlexWrapper>
        {albums.map((albumInfo) => (
          <AlbumDetails key={albumInfo.title} albumInfo={albumInfo} />
        ))}
      </FlexWrapper>
    </>
  );
};
