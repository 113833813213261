import {
  AppleMusicIcon,
  BandcampIcon,
  EmailIcon,
  FacebookIcon,
  InstagramIcon,
  SoundcloudIcon,
  SpotifyIcon,
  YouTubeIcon,
} from '../icons';

export const contactMedia = [
  {
    name: 'Facebook',
    link: 'https://www.facebook.com/Allochiriatheband/',
    icon: <FacebookIcon />,
  },
  {
    name: 'Instagram',
    link: 'https://www.instagram.com/allochiria.the.band',
    icon: <InstagramIcon />,
  },
  {
    name: 'Spotify',
    link: 'https://open.spotify.com/artist/2PWwVHwBJwSIz5SJ7nkmtt',
    icon: <SpotifyIcon />,
  },
  {
    name: 'Bandcamp',
    link: 'https://allochiria.bandcamp.com/',
    icon: <BandcampIcon />,
  },
  {
    name: 'Apple Music',
    link: 'https://music.apple.com/us/artist/allochiria/866136825',
    icon: <AppleMusicIcon />,
  },
  {
    name: 'Soundcloud',
    link: 'https://soundcloud.com/allochiria',
    icon: <SoundcloudIcon />,
  },
  {
    name: 'YouTube',
    link: 'https://www.youtube.com/@Allochiriatheband',
    icon: <YouTubeIcon />,
  },
  {
    name: 'Email',
    link: 'mailto:info@allochiria.com',
    icon: <EmailIcon />,
  },
];
