import styled from 'styled-components';

export const SectionHeader = styled.h2`
  text-align: center;
  border-bottom: 1px solid black;
  width: 50%;
  margin: 1rem auto;
  padding-top: 50px;

  @media only screen and (max-width: 768px) {
    width: 90%;
  }
`;
