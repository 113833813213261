export const albums = [
  {
    title: 'COMMOTION',
    year: 2023,
    coverArt: { src: 'commotion_cover_art.jpg', alt: 'Commotion album cover' },
    ultragrim: true,
    preorder: false,
    iframe: {
      title: 'Commotion',
      src: 'https://bandcamp.com/EmbeddedPlayer/album=1930976721/size=small/bgcol=ffffff/linkcol=0687f5/transparent=true/',
      link: 'https://allochiria.bandcamp.com/album/commotion',
      linkTitle: 'Commotion by Allochiria',
    },
    // buyLink: 'https://www.venerateindustries.store/allochiria',
    buyLink: 'https://allochiria.bandcamp.com/album/commotion',
    label: {
      name: 'Venerate Industries',
      link: 'https://www.venerateindustries.com/',
    },
    credits: [
      'Produced, recorded and mixed by Jacopo Fokas at Villa Giuseppe',
      'Mastered by Jens Bogren at Fascination Street Studios',
    ],
  },
  {
    title: 'THROES',
    year: 2017,
    coverArt: { src: 'throes_cover.jpg', alt: 'Throes album cover' },
    ultragrim: true,
    iframe: {
      title: 'counting fives',
      src: 'https://bandcamp.com/EmbeddedPlayer/album=1723118053/size=small/bgcol=ffffff/linkcol=de270f/track=1229696372/transparent=true/',
      link: 'https://music.allochiria.com/album/throes',
      linkTitle: 'Throes by Allochiria',
    },
    buyLink: 'https://music.allochiria.com/album/throes',
    label: { name: 'Art of Propaganda Records', link: 'http://aoprecords.de/' },
    credits: [
      'Drums and bass recorded at Illusion Studios',
      'Guitars and vocals recorded at Universe Studios',
      'Mixed at Universe Studios',
      'Mastered at Sweetspot Studios',
    ],
  },
  {
    title: 'OMONOIA',
    coverArt: { src: 'omonoia_cover.jpg', alt: 'Omonoia album cover' },
    year: 2014,
    ultragrim: true,
    iframe: {
      title: 'we crave what we lack',
      src: 'https://bandcamp.com/EmbeddedPlayer/album=614922088/size=small/bgcol=ffffff/linkcol=de270f/track=1493448002/transparent=true/',
      link: 'https://music.allochiria.com/album/omonoia',
      linkTitle: 'Omonoia by Allochiria',
    },
    buyLink: 'https://music.allochiria.com/album/omonoia',
    label: false,
    credits: ['Recorded, mixed and mastered at Universe Studios'],
  },
  {
    title: 'ALLOCHIRIA EP',
    coverArt: { src: 'ep_cover.jpg', alt: 'EP cover' },
    year: 2010,
    ultragrim: false,
    iframe: {
      title: 'aokigahara forest',
      src: 'https://bandcamp.com/EmbeddedPlayer/album=992698848/size=small/bgcol=ffffff/linkcol=de270f/track=4070534741/transparent=true/',
      link: 'https://music.allochiria.com/album/allochiria-ep',
      linkTitle: 'Allochiria EP by Allochiria',
    },
    buyLink: 'https://music.allochiria.com/album/allochiria-ep',
    label: false,
    credits: [
      'Recorded, mixed and mastered at In The Core Media Studios',
      'Artwork & Layout by Panagiotis Filis',
    ],
  },
];
