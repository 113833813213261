import styled from 'styled-components';

export const FlexWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 50%;
  margin: 0 auto;

  @media only screen and (max-width: 768px) {
    max-width: 90%;
  }
`;
