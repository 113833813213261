import styled from 'styled-components';

export const Content = styled.div`
  text-align: center;
  max-width: 50%;
  margin: 0 auto;

  @media only screen and (max-width: 1440px) {
    max-width: 90%;
  }
`;
