import styled from 'styled-components';

export const ContactEmails = () => {
  return (
    <>
      <p>
        General inquiries:
        <MailLink href="mailto:info@allochiria.com">
          info@allochiria.com
        </MailLink>
      </p>
      <p>
        Booking:
        <MailLink href="mailto:booking@allochiria.com">
          booking@allochiria.com
        </MailLink>
      </p>
      <p>
        Management:
        <MailLink href="management@allochiria.com">
          management@allochiria.com
        </MailLink>
      </p>
    </>
  );
};

const MailLink = styled.a`
  color: black;
  margin-left: 0.375rem;

  @media only screen and (max-width: 768px) {
    width: 100%;
    display: inline-block;
    margin-left: unset;
  }
`;
