import styled from 'styled-components';
import { Section } from './components/Section';
import { Masthead } from './Masthead';
import {
  SectionHeader,
  TextContent,
  ContactEmails,
  ContactMedia,
} from './components';
import { epkLinks } from './data/epkLinks';

export const EPK = () => (
  <>
    <Masthead />
    <Section>
      <SectionHeader>EPK</SectionHeader>
      <TextContent style={{ textAlign: 'center' }}>
        <List>
          {epkLinks.map((item) => (
            <li key={item.link}>
              <Link
                href={item.link}
                title={item.label}
                target="_blank"
                rel="noreferrer"
              >
                {item.label}
              </Link>
            </li>
          ))}
        </List>
        <h3 style={{ marginTop: '2rem' }}>Contact details</h3>
        <ContactEmails />
        <h3 style={{ marginTop: '2rem' }}>Phone numbers</h3>
        <p>Eirini: +32 478 79 82 73</p>
        <p>Stef: +30 694 81 34 391</p>
      </TextContent>
    </Section>
    <Section>
      <h3 style={{ marginTop: '3rem', textAlign: 'center' }}>Social media</h3>
      <ContactMedia />
    </Section>
  </>
);

const Link = styled.a`
  color: black;
`;

const List = styled.ul`
  list-style: none;
  padding: 0;

  > li {
    padding: 8px 0;
  }
`;
