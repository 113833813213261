export const videos = [
  {
    link: 'https://www.youtube.com/embed/1BxnFijrxVo',
    title: 'Casualties live at Gagarin205 in Athens 2023',
  },
  {
    link: 'https://www.youtube.com/embed/O8EiluK1wIc',
    title: 'We Have Nothing by Allochiria',
  },
  {
    link: 'https://www.youtube.com/embed/YxaCE4eA0Jo',
    title: 'Darklight by Allochiria',
  },
  {
    link: 'https://www.youtube.com/embed/rPF9Bjr8jms',
    title: 'Cracking Fractals by Allochiria',
  },
  {
    link: 'https://www.youtube.com/embed/hTXB-IeaORk',
    title: 'K. by Allochiria',
  },
  {
    link: 'https://www.youtube.com/embed/Gv19IrWSt0s',
    title: 'We Crave What We Lack by Allochiria',
  },
  {
    link: 'https://www.youtube.com/embed/on1hCgu6_3U',
    title: 'Archetypal Attraction To Circular Things by Allochiria',
  },
];
