import styled from 'styled-components';

export const BandPhoto = () => {
  return (
    <>
      <Photo src="alossi_lowres-8.jpg" alt="band photo by Ioanna Kitrou" />
      <Caption>Photo © Cristina Alossi, 2023</Caption>
    </>
  );
};

const Photo = styled.img`
  width: 100%;
  max-width: 1024px;
  margin: 0 auto;
  display: block;
`;

const Caption = styled.span`
  font-size: 12px;
  width: 100%;
  text-align: center;
  display: inline-block;
`;
